import { take as take$1, takeMaybe as takeMaybe$1, takeEvery as takeEvery$1, takeLatest as takeLatest$1, takeLeading as takeLeading$1, put as put$1, putResolve as putResolve$1, call as call$1, apply as apply$1, cps as cps$1, fork as fork$1, spawn as spawn$1, join as join$1, cancel as cancel$1, select as select$1, actionChannel as actionChannel$1, flush as flush$1, cancelled as cancelled$1, setContext as setContext$1, getContext as getContext$1, delay as delay$1, throttle as throttle$1, debounce as debounce$1, retry as retry$1, all as all$1, race as race$1 } from 'redux-saga/effects';

/* This file is generated. Do not edit it. */

/* This file is generated. Do not edit it. */
function* take(...args) {
  return yield take$1(...args);
}

/* This file is generated. Do not edit it. */
function* takeMaybe(...args) {
  return yield takeMaybe$1(...args);
}

/* This file is generated. Do not edit it. */
function* takeEvery(...args) {
  return yield takeEvery$1(...args);
}

/* This file is generated. Do not edit it. */
function* takeLatest(...args) {
  return yield takeLatest$1(...args);
}

/* This file is generated. Do not edit it. */
function* takeLeading(...args) {
  return yield takeLeading$1(...args);
}

/* This file is generated. Do not edit it. */
function* put(...args) {
  return yield put$1(...args);
}

/* This file is generated. Do not edit it. */
function* putResolve(...args) {
  return yield putResolve$1(...args);
}

/* This file is generated. Do not edit it. */
function* call(...args) {
  return yield call$1(...args);
}

/* This file is generated. Do not edit it. */
function* apply(...args) {
  return yield apply$1(...args);
}

/* This file is generated. Do not edit it. */
function* cps(...args) {
  return yield cps$1(...args);
}

/* This file is generated. Do not edit it. */
function* fork(...args) {
  return yield fork$1(...args);
}

/* This file is generated. Do not edit it. */
function* spawn(...args) {
  return yield spawn$1(...args);
}

/* This file is generated. Do not edit it. */
function* join(...args) {
  return yield join$1(...args);
}

/* This file is generated. Do not edit it. */
function* cancel(...args) {
  return yield cancel$1(...args);
}

/* This file is generated. Do not edit it. */
function* select(...args) {
  return yield select$1(...args);
}

/* This file is generated. Do not edit it. */
function* actionChannel(...args) {
  return yield actionChannel$1(...args);
}

/* This file is generated. Do not edit it. */
function* flush(...args) {
  return yield flush$1(...args);
}

/* This file is generated. Do not edit it. */
function* cancelled(...args) {
  return yield cancelled$1(...args);
}

/* This file is generated. Do not edit it. */
function* setContext(...args) {
  return yield setContext$1(...args);
}

/* This file is generated. Do not edit it. */
function* getContext(...args) {
  return yield getContext$1(...args);
}

/* This file is generated. Do not edit it. */
function* delay(...args) {
  return yield delay$1(...args);
}

/* This file is generated. Do not edit it. */
function* throttle(...args) {
  return yield throttle$1(...args);
}

/* This file is generated. Do not edit it. */
function* debounce(...args) {
  return yield debounce$1(...args);
}

/* This file is generated. Do not edit it. */
function* retry(...args) {
  return yield retry$1(...args);
}

/* This file is generated. Do not edit it. */
function* all(...args) {
  return yield all$1(...args);
}

/* This file is generated. Do not edit it. */
function* race(...args) {
  return yield race$1(...args);
}

export { actionChannel, all, apply, call, cancel, cancelled, cps, debounce, delay, flush, fork, getContext, join, put, putResolve, race, retry, select, setContext, spawn, take, takeEvery, takeLatest, takeLeading, takeMaybe, throttle };
